import axios from "axios";
import React, { useContext, useRef } from "react";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { useState } from "react";
import { selectOption } from "../../common/common-data/alphabets";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CreateDataApi,
  GetDataByIdApi,
  GetListApi,
  UpdateDataApi,
} from "../../common/api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { convertToSlug } from "../../common/count-words-and-characters";
import AppContext from "../../appContext";
import { CheckAccess } from "../../common/utils/roleAccess";
import { toast } from "react-toastify";
import saveToLogs from "../../common/utils/saveToLogs";
const apiUrl = config.apiUrl

const usePageshook = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  // const { appData, setAppData } = useContext(AppContext);
  const [appData, setAppData] = useState({})
  const statusRef = useRef(`open`);
  const navigate = useNavigate();
  let { id } = useParams();
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const [populateManageData, setPopulateManageData] = useState(false)
  const [populateFilterData, setPopulateFilterData] = useState(false)

  const [isEditingById, setIsEditingById] = useState('')
  const [editEnabled, setEditEnabled] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [storiesCount, setStoriesCount] = useState(0)
  const [totalPages, setTotalPages] = useState(null);
  const [currentTab, setCurrentTab] = useState('')
  const [storiesList, setStoriesList] = useState([]);
  const [submittedPage, setSubmittedPage] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);
  const [timelines, setTimelines] = useState([]);
  const [authorsName, setAuthorsName] = useState([])
  const [searchWord, setSearchWord] = useState('')

  const [pageList, setPageList] = useState([]);
  const [sections, setSections] = useState([]);
  const [includeSectionList, setIncludeSectionList] = useState(selectOption);
  const [selectedIncludeSection, setSelectedIncludeSection] = useState([]);
  const [authorList, setAuthorList] = useState(selectOption);
  const [selectedAuthorList, setSelectedAuthorList] = useState([
    {
      id: user?._id,
      label: user?.name,
      value: user?.name,
    },
  ]);

  const [filterFields, setFilterFields] = useState({})

  const [tagsList, setTagsList] = useState(selectOption);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [firstApiCalled, setFirstApiCalled] = useState(false);

  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const [storyStatus, setStoryStatus] = useState("");
  const [formData, setFormData] = useState({
    file_path: "",
    title: "",

    meta_title: "",
    meta_description: "",
    meta_keywords: "",

    social_title: "",
    message: "",
    social_description: "",

    status: "Active",
    showHeader: false,
    showFooter: false,
    isSponsered_story: false,
    isCard_shareable: false,
    sponsored_by: "",
    isStory_promotional_message: false,
    disclaimer: true,
  });
  // State to manage cards and sub-cards
  const [cards, setCards] = useState([
    {
      sub_card: [
        {
          type: "Text",
          title: "",
          attributes: "",
          actionUrl: "",
          description: "",
          isExternal: false,
          noFollow: false,
          question: "",
          question_attributes: "",
          answer: "",
          answer_attributes: "",
          summary: "",
          images: "",
          file_id: "",
          img_caption: "",
          img_attribution: "",
          img_alt: "",
          img_credit: "",
          link_story: "",
          story: "",
          story_slug: "",
        },
      ],
    },
  ]);

  const HandleSelectChange = (type, e) => {
    if (type === "tags") {
      setValue("tags", e);
      setSelectedTagsList(e);
    } else if (type === "sections") {
      setValue("sections", e);
      setSelectedIncludeSection(e);
    } else if (type === "authors") {
      setValue("authors", e);
      setSelectedAuthorList(e);
    }
  };
  const [socialCard, setSocialCard] = useState([
    {
      social_share_message: "",
      is_raftaar_netlabs: false,
      is_raftaar: false,
      is_scheduled: false,
      scheduled_date: "",
    },
  ]);
  const handleAddElement = (type) => {
    const newElement = {
      social_share_message: "",
      is_raftaar_netlabs: false,
      is_raftaar: false,
      is_scheduled: false,
      scheduled_date: "",
    };
    setSocialCard([...socialCard, newElement]);
  };
  const handleDeleteElement = (index) => {
    const updateSocialCard = [...socialCard];
    updateSocialCard.splice(index, 1);
    setSocialCard(updateSocialCard);
  };
  const handleSocialCardChange = (index, field, value) => {
    const updateSocialCard = [...socialCard];
    updateSocialCard[index][field] = value;
    setSocialCard(updateSocialCard);
  };

  const capitaliseSentence = (sentence) => {
    if (!sentence) return null
    sentence = sentence.replace('-', ' ')
    let capitalizedString = sentence.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
    return capitalizedString
  }

  const getSectionParentChain = async (section_name) => {
    const url = `${config.apiUrl}/section?sectionName=${section_name}`
    const response = await fetch(url)
    const sectionData = await response.json()

    const section = sectionData?.sections?.[0]

    if (section?.parent_section?.length > 0) {
      return await getSectionParentChain(section?.parent_section?.[0]) + " > " + capitaliseSentence(section?.section_name)
    }
    else {
      return await capitaliseSentence(section?.section_name)
    }
  }

  const fetchOurSection = (inputValue) => {
    axios
      .get(`${config.apiUrl}/section/with/parent-chain`, {
        params: {
          status: "Active",
          ...(inputValue && { searchKeyWord: inputValue })
        },
      })
      .then((response) => {
        let arr = [];
        let activeSectionArr = [];
        if (response.data?.sections.length > 0) {
          let result = response?.data?.sections?.map(async (sec) => {
            if (sec?.status === "Active") {
              let sectionString = capitaliseSentence(sec?.section_name)
              if (sec?.parent_section && sec?.parent_section?.length > 0) {
                let subSectionString = sec?.parent_section
                  ?.map((subSec) => capitaliseSentence(subSec))
                  .join(" > ");
                // sectionString += " > " + subSectionString;
                sectionString = subSectionString + " > " + sectionString;
              }

              let parent_section = sec?.parent_section?.[0]

              if (parent_section) {
                // const parentChain = await getSectionParentChain(sec.section_name)
                sectionString = sec?.parent_chain
              }

              arr?.push({
                label: sectionString,
                value: sec?._id,
              });

              activeSectionArr?.push(sec);

              setSections(activeSectionArr);
              return sectionString;
            }
          });
          setIncludeSectionList(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact requests:", error);
      });
  };
  const FetchTagsList = async (tagName) => {
    try {
      let query = (tagName?.trim() !== "") ? { name: tagName } : {}
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints.tags}`, {
          params: { ...query },
        })
        .then((response) => {
          let arr = [];
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              arr?.push({
                label: element?.name,
                value: element?._id,
              });
              setTagsList(arr);
            });
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAuthorList = async () => {
    try {
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints?.user}`)
        .then((response) => {
          let arr = [];

          const names = response?.data?.users?.map(user => user.name);
          const distinctNames = [...new Set(names)].sort();

          setAuthorsName(distinctNames)
          if (response?.data?.users.length > 0) {
            response?.data?.users.forEach((element) => {
              if (element?.user_role?.name === "author" || element?.user_role?.name === "Admin" || element?.user_role?.name === "Editor" || element?.user_role?.name === "Guest Contributor") {
                arr?.push({
                  label: element?.name,
                  value: element?.name,
                  id: element?._id,
                });
              }
            });
            setAuthorList(arr);
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (populateFilterData) {
      fetchAuthorList()
      setPopulateFilterData(null)
    }
  }, [populateFilterData]);

  useEffect(() => {
    if (populateManageData) {
      fetchOurSection();
      FetchTagsList();
      fetchAuthorList()
      setPopulateManageData(null)
    }
  }, [populateManageData])

  const HandleUploadImage = async (files) => {
    let data = {};

    try {
      if (files?.length > 0) {
        const formData = new FormData();
        data.status = "Active";
        const res = await axios.post(
          `${config.apiUrl}/${apiEndpoints.upload}`,
          { files: files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res?.data?.length) {
          setValue("file_name", res?.data[0]?.file_name);
          setValue("file_path", res?.data[0]?.file_path);
          setValue("file_Id", res?.data[0]?._id);
          // setImgUrl(`${config?.apiUrl}/${res?.data[0]?.file_path}`);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const [currentImgId, setCurrentImgId] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const fetchStoryById = async (_id) => {
    const response = await GetDataByIdApi(
      `${apiEndpoints.storiesById(id || _id)}`
    );
    if (response) {
      // setStoryStatus(response?.status);
      setValue("status", response?.status);
      statusRef.current = response?.status;

      if (response.cards.length > 0) {
        setValue("cards", response?.cards);
        const cardsCopy = _.cloneDeep(response?.cards);
        cardsCopy.sort((a, b) => a.s_no - b.s_no);
        setCards(cardsCopy);
        setValue("story_id", response?._id);
        response.cards?.forEach((element, cIndex) => {
          if (element?.sub_card?.length > 0) {
            element?.sub_card?.forEach((subItem, sIndex) => {
              if (subItem?.type === "Also Read") {
                setValue(
                  `cards[${cIndex}].sub_card[${sIndex}].link_story`,
                  subItem?.link_story
                );
              }
            });
          }
        });
      }

      setValue("is_breaking_news", response?.is_breaking_news)
      setValue("breaking_title", response?.breaking_title)
      setValue("breaking_description", response?.breaking_description)

      setValue("title", response?.title);
      setValue("sub_title", response?.sub_title);
      setValue("intro", response?.intro)
      setValue("is_child_story", response?.is_child_story)

      if (response?.status !== 'published' && !(response?.status === 'scheduled' && response?.publishDate <= new Date().toISOString())) setEditEnabled(true)

      if (response?.is_editing_by?._id === user?._id) {
        setEditEnabled(true)
      }
      else {
        setEditEnabled(false)
      }

      setFormData({
        meta_title: response?.seo?.meta_title,
        meta_description: response?.seo?.meta_description,
        meta_keywords: response?.seo?.meta_keywords,

        user: user,
        is_editing_by: response?.is_editing_by,

        status: response?.status,
        is_child_story: response?.is_child_story,
        _id: response?._id,
        story_type: response?.story_type,

        intro: response?.intro,
        is_live: response?.is_live,
        parent_story_id: response?.parent_story_id,

        breaking_title: response?.breaking_title,
        breaking_description: response?.breaking_description,

        publish_date: response?.publish_date,

        title: response?.title,
        sub_title: response?.sub_title,

        is_breaking_news: response?.is_breaking_news,
        isCard_shareable: response?.story_features?.isCard_shareable,
        isSponsered_story: response?.story_features?.isSponsered_story,
        sponsored_by: response?.story_features?.sponsored_by,
        disclaimer: response?.disclaimer,
        isStory_promotional_message:
          response?.story_features?.isStory_promotional_message,
      });
      if (response?.hero_image) {
        const imgPrefix = (response.is_migrated) ? config.migratedFileUrl : config.fileUrl
        setImgUrl(`${imgPrefix}/${response?.hero_image?.file_path}`);
        setValue("file_path", response?.hero_image?.file_path);
        setValue("file_id", response?.hero_image?.file_id);

        setCurrentImgId(response?.hero_image?.file_id);
        setValue("caption", response?.hero_image?.caption);
        setValue("alt", response?.hero_image?.alt);
        setValue("credit", response?.is_migrated ? response?.hero_image?.alt : response?.hero_image?.credit);
      }

      if (response?.sections?.length > 0) {
        setSelectedIncludeSection(response.sections);
        setValue("sections", response.sections);

        // HandleGetSection([{ value: response?.sections[0]?._id }]);
      }

      if (response?.tags?.length > 0) {
        setSelectedTagsList(response.tags);
        setValue("tags", response.tags);
      }

      if (response?.authors) {
        setSelectedAuthorList(response?.authors);
      }

      setAppData({ story_Id: response?._id, status: response?.status });
      setValue("authors", response?.authors);
      setValue("custom_slug", response?.custom_slug);
      setValue("slug_preview", response?.slug_preview);
      setValue("canonical_url", response?.canonical_url);

      if (response?.seo) {
        // setFormData({ ...formData, ...response?.seo });
        setValue("meta_description", response?.seo?.meta_description);
        setValue("meta_keywords", response?.seo?.meta_keywords);
        setValue("meta_title", response?.seo?.meta_title);
      }

      if (response?.social) {
        setValue("social_title", response?.social?.social_title);
        setValue("message", response?.social?.message);
        setValue("platform", response?.social?.platform);
      }

      setValue("social_description", response?.social_description);

      if (response?.social_card?.length > 0) {
        setSocialCard(response?.social_card);
      }

      if (response) {
        await fetchTimeline(`${response?._id}`);
      }
      HandleGetSection();
    }
  };
  // S U B M I T  S T O R Y
  const onSubmit = async (data) => {
    let httpMethod = null
    let url = null
    let logsPayload = null
    let logsStoryId = null

    try {
      data.is_breaking_news = formData.is_breaking_news;
      data.disclaimer = formData.disclaimer;

      data.hero_image = {
        file_name: data?.file_name,
        file_path: data?.file_path,
        file_id: data?.file_id,
        caption: data?.caption,

        alt: data?.alt,
        credit: data?.credit,
      };
      data.story_features = {
        isSponsered_story: formData?.isSponsered_story,
        sponsored_by: formData?.sponsored_by,
        isCard_shareable: formData?.isCard_shareable,
        isStory_promotional_message: formData?.isStory_promotional_message,
      };
      data.seo = {
        meta_title: data?.meta_title,
        meta_description: data?.meta_description,
        meta_keywords: data?.meta_keywords,
      };
      data.social = {
        social_title: data?.social_title,
        message: data?.message,
        platform: data?.platform,
      };
      data.social_card = socialCard;
      data.created_by = user?.name;
      data.story_type = data?.story_type || `Text Story`;
      data.status = `submitted`;
      statusRef.current = `submitted`;
      data.updated_on = new Date().toISOString()
      // setStoryStatus(`submitted`);

      data.slug = data?.slug;

      setLoading(true);
      if (!appData?.story_Id) {
        httpMethod = 'POST'
        url = `${apiEndpoints?.stories}`
        logsPayload = data
        logsStoryId = null

        data.is_editing_by = user?._id
        const response = await CreateDataApi(`${apiEndpoints?.stories}`, data);
        const timeline = await CreateDataApi(apiEndpoints?.timeline, {
          story_id: response?._id,
          action_by: user?._id,
          action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
          action_type: `submitted`,
        });
        await fetchTimeline(`${response?._id}`);
        if (response) {
          // setStoryStatus(response?.status);
          setIsEditingById(user?._id)
          setFormData({ ...formData, is_editing_by: user })
          setEditEnabled(true)
          setAppData({ story_Id: response?._id, status: response?.status });
          setValue("story_id", response?._id);
          setLoading(false);
          if (user?.user_role?.slug === "guest-contributor") {
            navigate("/story");
            window?.location?.reload();
          }
        }
      } else {
        httpMethod = 'PUT'
        url = `${apiEndpoints.storiesById(data?.story_id)}`
        logsPayload = data
        logsStoryId = data?.story_id

        data.is_editing_by = null

        const response = await UpdateDataApi(
          `${apiEndpoints.storiesById(data?.story_id)}`,
          data
        );
        await CreateDataApi(apiEndpoints?.timeline, {
          story_id: response?._id,
          action_by: user?._id,
          action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
          action_type: "submitted",
        });
        await fetchTimeline(`${response?._id}`);

        if (response) {
          if (user?.user_role?.name === "Guest Contributor") {
            setIsEditingById(null)
            setFormData({ ...formData, is_editing_by: null })
            setEditEnabled(false)
          }
          setValue("status", response?.status);
          setStoryStatus(response?.status);
          // setAppData({ story_Id: response?._id });
          setLoading(false);

          if (user?.user_role?.slug === "guest-contributor") {
            navigate("/story");
            window?.location?.reload();
          }
        }
      }
    } catch (error) {
      // toast.error("Error creating page");
      const errorDetails = {
        message: error.message,
        stack: error.stack
      }
      await saveToLogs({
        source: 'onsubmit',
        payload: logsPayload,
        error_message: errorDetails,
        url: url,
        http_method: httpMethod,
        story_id: logsStoryId,
        log_type: 'text story'
      })
    }
  };
  // U P D A T E  S T O R Y
  const onUpdate = async (data) => {
    try {
      data.hero_image = {
        file_name: data?.file_name,
        file_path: data?.file_path,
        file_id: data?.file_id,
        caption: data?.caption,

        alt: data?.alt,
        credit: data?.credit,
      };
      data.story_features = {
        isSponsered_story: formData?.isSponsered_story,
        sponsored_by: formData?.sponsored_by,
        isCard_shareable: formData?.isCard_shareable,
        isStory_promotional_message: formData?.isStory_promotional_message,
      };
      data.seo = {
        meta_title: data?.meta_title,
        meta_description: data?.meta_description,
        meta_keywords: data?.meta_keywords,
      };
      data.social = {
        social_title: data?.social_title,
        message: data?.message,
        platform: data?.platform,
      };
      data.social_card = socialCard;
      data.updated_by = user?.name;
      data.updated_on = moment().format("MMMM DD YYYY, hh:mm:ss a");
      data.status = "submitted";
      setStoryStatus(`submitted`);
      data.story_type = `Text Story`;
      data.is_breaking_news = formData?.is_breaking_news;
      data.disclaimer = formData?.disclaimer;
      data.slug = data?.slug;
      data.updated_on = new Date().toISOString();

      if (
        data?.sections &&
        data?.tags &&
        data?.authors &&
        data?.meta_title !== "" &&
        data?.meta_description !== ""
      ) {
        setLoading(true);
        const response = await UpdateDataApi(
          `${apiEndpoints.storiesById(id)}`,
          data
        );
        if (response) {
          const timeline = await CreateDataApi(apiEndpoints?.timeline, {
            story_id: response?._id,
            action_by: user?._id,
            action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
            action_type: `updated`,
          });
          await fetchTimeline(`${response?._id}`);
          setStoryStatus(response?.status);
          setAppData({ story_Id: response?._id });
          setLoading(false);
          setValue("story_id", response?._id);
          setValue("status", response?.status);
        }
      }
    } catch (err) {
      console.log(err?.message, "error");
    }
  };
  const HandleModalDataSubmit = (data) => {
    handleClose();
  };
  //
  // const fetchOurStory = async (status, query) => {
  //   const response = await GetListApi(`${apiEndpoints.stories}`, {
  //     page: currentPage,
  //     pageSize: 20,
  //     pagination: true,
  //     status: status,
  //     q: query,
  //   });
  //   if (response?.stories) {
  //     setStoriesList(response?.stories);
  //     setTotalPages(response?.pages);
  //   }
  // };

  const roundStoriesCount = count => {
    if (count >= 1000) {
      return (count / 1000)?.toFixed(1) + 'k';
    }
    return count?.toString();
  }

  const fetchOurStory = async (status, query, searchInput = '', story_type = '', story_author = '') => {
    try {

      const userData = localStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      setStoriesCount(0)

      let authorQuery = null

      if (user?.user_role?.name === 'Guest Contributor') {
        authorQuery = { "authorId": user?._id }
      }

      if (status && status === 'All') status = ''
      else if (!status && currentTab && currentTab !== 'All') status = currentTab.toLowerCase()


      setLoading(true);

      let tempQuery = null
      if (filterFields?.template && filterFields?.template !== 'select story type') tempQuery = { story_type: filterFields?.template }
      if (story_type) tempQuery = { story_type: story_type }
      // if (filterFields?.section) tempQuery = { ...tempQuery, "sections.label": filterFields?.section }
      if (filterFields?.author && filterFields?.author !== 'select author') tempQuery = { ...tempQuery, "authors.label": filterFields?.author }
      if (story_author) tempQuery = { ...tempQuery, "authors.label": story_author }
      if (filterFields?.publishDate) tempQuery = { ...tempQuery, "publishDate": filterFields?.publishDate }
      if (filterFields?.updatedDate) tempQuery = { ...tempQuery, "updateDate": filterFields?.updatedDate }
      if (filterFields?.section?.value) tempQuery = { ...tempQuery, "section": filterFields?.section?.value }

      // if (searchWord.trim() !== '' || searchInput?.trim() !== '') {
      //   query = searchInput || searchWord
      // }

      query = searchInput || searchWord

      const response = await GetListApi(`${apiEndpoints.stories}`, {
        page: currentPage,
        pageSize: 50,
        pagination: true,
        status: status?.toLowerCase(),
        isBackend: true,
        q: query,
        ...(tempQuery && tempQuery),
        ...(authorQuery && authorQuery)
      });
      if (response?.stories) {
        setStoriesList(response.stories);
        setTotalPages(Math.ceil(response.count / 50));
        setStoriesCount(roundStoriesCount(response?.count))
      }
    } catch (error) {
      console.error("Error fetching stories:", error);
    } finally {
      setLoading(false);
    }
  };

  // let count = useRef(0)

  useEffect(() => {
    // if (count.current === 0) {
    let path = window?.location?.pathname
    if (!path.includes('create') && !path.includes('update'))
      fetchOurStory();
    // count.current = count.current + 1
    // }
  }, [currentPage]);

  const handleDelete = (id, child_story_id = '', parent_story_id = '') => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setLoading(true);
      if (result.isConfirmed) {
        if (parent_story_id) {
          //if it is child story
          axios.delete(`${config.apiUrl}/stories/child-story/${parent_story_id}`).then(res => { console.log({ msg: 'child story deleted' }) })

          const userData = localStorage.getItem("user");
          const user = userData ? JSON.parse(userData) : null;

          axios
            .put(`${config.apiUrl}/stories/${parent_story_id}`, { status: 'deleted', deleted_by: user?._id, deleted_on: new Date().toISOString(), child_story_id: null })
            .then((response) => {
              setLoading(false);
              fetchOurStory();
            })
            .catch((error) => {
              console.error("Error deleting item:", error?.message);
            });
        }
        else {
          //parent story
          const userData = localStorage.getItem("user");
          const user = userData ? JSON.parse(userData) : null;

          axios
            .put(`${config.apiUrl}/stories/${id}`, { status: 'deleted', deleted_by: user?._id, deleted_on: new Date().toISOString(), child_story_id: null })
            .then((response) => {
              setLoading(false);
              fetchOurStory();
            })
            .catch((error) => {
              console.error("Error deleting item:", error?.message);
            });
        }
      } else if (result?.isDismissed) {
        setLoading(false);
      }
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const HandleGetSection = async (e) => {
    if (statusRef.current !== "published") {
      const values = getValues();
      let convertedSlug = convertToSlug(values?.custom_slug);
      if (values?.sections?.length > 0) {
        let slugUrl = values?.sections[0]?.label
          ?.split(" > ")
          // ?.reverse()
          ?.join("/")
          ?.replace(/ /g, "-");

        setValue(
          "slug_preview",
          `${values?.sections[0]?.label
            ?.split(" > ")
            // ?.reverse()
            ?.join("/")
            ?.toLowerCase()
            ?.replace(/ /g, "-")}/${convertedSlug}`
        );
        setSlug(slugUrl?.split("/")?.reverse()?.join("/"));
      } else {
        setSlug("");
        setValue("slug_preview", "");
      }
    }
  };

  useEffect(() => {
    if (!id) {
      setEditEnabled(true)
    }
    if (id) {
      fetchStoryById();
      fetchTimeline(id);
    }
  }, [id]);

  const onUpdateStatus = async (status) => {
    const story = getValues();
    story.status = status;
    story.updated_on = new Date().toISOString()
    setStoryStatus(status);
    statusRef.current = status;
    setLoading(true);
    const response = await UpdateDataApi(
      `${apiEndpoints.storiesById(story?.story_id || id)}`,
      story
    );

    if (response) {
      const timeline = await CreateDataApi(apiEndpoints?.timeline, {
        story_id: response?._id,
        action_by: user?._id,
        action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
        action_type: status,
      });
      await fetchTimeline(`${response?._id}`);
      setLoading(false);
      setValue("story_id", response?._id);
    }
  };

  const onAutoSaveStory = async () => {
    let httpMethod = null
    let url = null
    let logsPayload = null
    let logsStoryId = null

    try {
      const storyData = getValues();
      if (!storyData?.title) {
        toast.error('please enter title')
        return
      }

      const isExistingStory = storyData?.story_id;
      storyData.is_breaking_news = formData?.is_breaking_news;
      storyData.disclaimer = formData?.disclaimer;
      storyData.hero_image = {
        file_name: storyData?.file_name,
        file_path: storyData?.file_path,
        file_id: storyData?.file_id,
        caption: storyData?.caption,
        attribution: storyData?.attribution,
        alt: storyData?.alt,
        credit: storyData?.credit,
      };
      storyData.story_features = {
        isSponsered_story: formData?.isSponsered_story,
        sponsored_by: formData?.sponsored_by,
        isCard_shareable: formData?.isCard_shareable,
        isStory_promotional_message: formData?.isStory_promotional_message,
      };
      storyData.seo = {
        meta_title: storyData?.meta_title,
        meta_description: storyData?.meta_description,
        meta_keywords: storyData?.meta_keywords,
      };
      storyData.social = {
        social_title: storyData?.social_title,
        message: storyData?.message,
        platform: storyData?.platform,
      };
      storyData.social_card = socialCard;
      storyData.story_type = storyData?.story_type || `Text Story`;
      storyData.updated_on = new Date().toISOString()
      // return; 
      if (!id || !appData.story_Id) {
        storyData.status =
          statusRef.current !== `` ? statusRef.current : `open`;
      }

      if (
        statusRef.current === "published" ||
        statusRef.current === "retract" ||
        statusRef.current === "rejected" ||
        statusRef.current === "scheduled"
      ) {
        statusRef.current = `open`;
        storyData.status = `open`;
      }

      storyData.is_editing_by = user?._id
      setEditEnabled(true)
      setFormData({ ...formData, is_editing_by: user, user })

      const apiEndpoint =
        appData.story_Id || id
          ? apiEndpoints.storiesById(appData.story_Id || id)
          : apiEndpoints.stories;

      let response;

      if (!appData.story_Id && !id) {
        url = apiEndpoint
        logsPayload = storyData
        httpMethod = 'POST'

        setIsSaving(true);
        response = await CreateDataApi(apiEndpoint, storyData);

        if (response?._id) {
          setFirstApiCalled(true)
          setAppData({ story_Id: response?._id });
          setValue("story_id", response?._id);
          id = response?._id
        }

        setIsEditingById(user?._id)
      } else if (appData.story_Id || id) {

        url = apiEndpoint
        logsPayload = storyData
        httpMethod = 'PUT'
        logsStoryId = appData?.story_Id || id

        setIsSaving(true);
        response = await UpdateDataApi(apiEndpoint, storyData);

        setAppData({ story_Id: response?._id });
        if (!isExistingStory || !id) {
          setValue("story_id", response?._id);
        }
      }

      console.log({ autoSaveResponseInTextStory: response, firstApiCalled, appData, id })

      if (!response?._id) {
        const tempStory = await fetch(`${apiUrl}/stories/from/title/${storyData?.title}`)
        if (tempStory?.story) response = tempStory
      }

      if (response && response?._id) {
        setAppData({ story_Id: response?._id, status: response?.status });
        // if (storyData?.story_type === 'Live Blog' && formData?.is_child_story) {
        if (formData?.is_live) {
          statusRef.current = 'approved'
          setFormData({ ...formData, status: 'approved' })
          setValue('status', 'approved')
        }
        const timeline = await CreateDataApi(apiEndpoints?.timeline, {
          story_id: response?._id,
          action_by: user?._id,
          action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
          action_type: `auto save`,
        });
        await fetchTimeline(`${response?._id}`);
        // setIsSaving(false);
        setAppData({ story_Id: response?._id });
        if (!isExistingStory || !id) {
          setValue("story_id", response?._id);
        }
        setIsSaving(false);
      }
    } catch (error) {
      console.error("Error auto-saving story", error?.message);
      const errorDetails = {
        message: error.message,
        stack: error.stack
      }
      await saveToLogs({
        source: 'onAutoSaveStory',
        payload: logsPayload,
        error_message: errorDetails,
        url: url,
        http_method: httpMethod,
        story_id: logsStoryId,
        log_type: 'text story'
      })
    }
    finally {
      setIsSaving(false);
    }
  };
  const RemoveEmptyFields = (cards) => {
    const filteredCards = cards.map((card) => ({
      ...card,
      sub_card: card.sub_card.filter((subCard) => !isSubCardEmpty(subCard)),
    }));
    return filteredCards;
  };

  const isSubCardEmpty = (subCard) => {
    return (
      (subCard?.title?.trim() === "<p><br></p>" || subCard?.title?.trim() === "") &&
      subCard.attributes.trim() === "" &&
      subCard.actionUrl.trim() === "" &&
      subCard.description.trim() === "" &&
      subCard.question.trim() === "" &&
      subCard.question_attributes.trim() === "" &&
      subCard.answer.trim() === "" &&
      subCard.answer_attributes.trim() === "" &&
      subCard.summary.trim() === "" &&
      subCard.images.trim() === "" &&
      subCard.img_caption.trim() === "" &&
      subCard.img_attribution.trim() === "" &&
      subCard.link_story.trim() === ""
    );
  };
  const hasEmptyField = (cards) => {
    for (const card of cards) {
      for (const subCard of card.sub_card) {
        if (isSubCardEmpty(subCard)) {
          return true;
        }
      }
    }
    return false;
  };
  // useEffect(() => {
  //   if (
  //     window.location.hash !== "#/story" &&
  //     !isImgEditorShown &&
  //     appData?.status !== "published"
  //   ) {
  //     const intervalId = setInterval((e) => {
  //       e?.preventDefault();
  //       onAutoSaveStory();
  //     }, 60000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, []);
  useEffect(() => {
    if (loading || isSaving) {
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setIsSaving(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [loading, isSaving]);
  // Handle changes in sub-card content
  const handleSubCardChange = (cardIndex, subCardIndex, field, value) => {
    const updatedCards = [...cards];
    updatedCards[cardIndex].sub_card[subCardIndex][field] = value;
    updatedCards[cardIndex].sub_card[subCardIndex]['updated_on'] = new Date()?.toISOString()
    updatedCards[cardIndex].sub_card[subCardIndex]['updated_by'] = user?._id
    setCards(updatedCards);
    setValue("cards", updatedCards);
  };

  const fetchTimeline = async (storyId) => {
    const response = await GetListApi(`${apiEndpoints.getTimeline}`, {
      story_id: storyId,
    });
    if (response?.timelines) {
      setTimelines(response?.timelines);
    }
  };
  return {
    id,
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    includeSectionList,
    setIncludeSectionList,
    selectedIncludeSection,
    setSelectedIncludeSection,
    authorList,
    setAuthorList,
    selectedAuthorList,
    setSelectedAuthorList,
    HandleSelectChange,
    HandleUploadImage,
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    submittedPage,
    setSubmittedPage,
    pageList,
    setPageList,
    formData,
    setFormData,
    socialCard,
    setSocialCard,
    handleAddElement,
    handleDeleteElement,
    handleSocialCardChange,
    show,
    setShow,
    handleClose,
    handleShow,
    HandleModalDataSubmit,

    totalPages,
    setTotalPages,
    storiesList,
    setStoriesList,
    handleDelete,
    currentPage,
    handlePageChange,
    onUpdate,
    navigate,
    cards,
    setCards,
    HandleGetSection,

    slug,
    setSlug,
    fetchOurStory,
    currentImgId,
    setCurrentImgId,
    imgUrl,
    setImgUrl,
    timelines,
    showTimeline,
    setShowTimeline,
    loading,
    isSubCardEmpty,
    hasEmptyField,
    RemoveEmptyFields,
    getValues,
    isImgEditorShown,
    setIsImgEditorShown,
    handleSubCardChange,
    onUpdateStatus,
    storyStatus,
    setStoryStatus,
    fetchTimeline,
    onAutoSaveStory,
    setLoading,
    statusRef,
    isSaving,
    setIsSaving,
    setCurrentPage,
    setStoriesList,
    authorList,
    sections,
    FetchTagsList,
    authorsName,
    filterFields,
    setFilterFields,
    setTotalPages,
    fetchOurSection,
    searchWord,
    setSearchWord,
    currentTab,
    setCurrentTab,
    editEnabled,
    setEditEnabled,
    storiesCount,
    setStoriesCount,
    isEditingById,
    setIsEditingById,
    populateManageData,
    setPopulateManageData,
    populateFilterData,
    setPopulateFilterData
  };
};

export default usePageshook;
