import { useEffect, useState } from "react";
import Loader from "../../common/utils/loader";
import { CheckAccess } from "../../common/utils/roleAccess";
import moment from "moment";
import { GetListApi } from "../../common/api/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Controller, useForm } from "react-hook-form";
import $ from 'jquery';
import 'daterangepicker';

const animatedComponents = makeAnimated();

const ListStory = () => {
    const { control, handleSubmit, watch, getValues, setValue } = useForm();

    const [loading, setLoading] = useState(false)
    const [rssBreaking, setRssBreaking] = useState([])
    const [rssFeedOptions, setRssFeedOptions] = useState([])
    const [sourceOptions, setSourceOptions] = useState([])
    const [genreOptions, setGenreOptions] = useState([])
    const [cities, setCities] = useState([])
    const [states, setStates] = useState([])
    const [countries, setCountries] = useState([])
    const [continents, setContinents] = useState([])

    const fetchRssBreaking = async (query = '') => {
        setRssBreaking([])
        setLoading(true)
        const result = await GetListApi(`rss-breaking${query}`)
        setRssBreaking(result)
        setLoading(false)
    }

    const fetchRssFeeds = async () => {
        const result = await GetListApi('rss-feed')
        const options = result?.map(item => ({ label: item?.name, value: item?.name }))
        setRssFeedOptions(options)
    }

    const fetchCommonMasters = async () => {
        const result = await GetListApi('common-masters')
        const sources = result?.data?.filter(item => item?.type === 'source')
        const genres = result?.data.filter(item => item?.type === 'genre')

        setSourceOptions(sources?.map(item => ({ label: item?.name, value: item?.name })))
        setGenreOptions(genres?.map(item => ({ label: item?.name, value: item?.name })))
    }

    const fetchUtilities = async () => {
        const data = (await GetListApi('utilities'))?.data
        const continentCountryData = data?.find(item => item?.name === 'continentCountryData')
        const stateCityData = data?.find(item => item?.name === 'stateCityDatastateCityData')

        const continents = Object?.keys(continentCountryData?.data)
        const countries = []
        continents?.map(item => countries?.push(...continentCountryData?.data?.[item]))

        setContinents(continents?.map(item => ({ label: item, value: item })))
        setCountries(countries?.map(item => ({ label: item, value: item })))

        const states = Object?.keys(stateCityData?.data)
        const cities = []
        states?.map(item => cities?.push(...stateCityData?.data?.[item]))

        setStates(states?.map(item => ({ label: item, value: item })))
        setCities(cities?.map(item => ({ label: item, value: item })))
    }

    useEffect(() => {
        const getData = async () => {
            await fetchRssBreaking()
            await fetchRssFeeds()
            await fetchCommonMasters()
            await fetchUtilities()
        }
        getData()
    }, [])

    useEffect(() => {
        const inputElement = $('input[name="daterangeCreated"]');

        $('input[name="daterangeCreated"]').daterangepicker({
            locale: {
                format: 'DD/MM/YYYY'
            },
            autoUpdateInput: false
        }, function (start, end, label) {
            let selectedDateRange = `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`
            inputElement.val(selectedDateRange);

            selectedDateRange = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')}`
            setValue('createdDate', selectedDateRange)
        });
    }, []);

    const filterButtonClick = async (title = '') => {
        const values = getValues()
        let query = ""

        Object.keys(values)?.map((key, index) => {
            if (key !== 'createdDate' && values?.[key]?.value) {
                if (!query) query = `?${key}=${values?.[key]?.value}`
                else query += `&${key}=${values?.[key]?.value}`

                setValue(`filters.${key}`, values?.[key]?.value)
            }
        })

        if (values?.createdDate) {
            query += !query ? query = '?' : '&'
            query += `startDate=${values?.createdDate?.split(' - ')?.[0]}`
            query += `&endDate=${values?.createdDate?.split(' - ')?.[1]}`

            setValue(`filters.startDate`, values?.createdDate?.split(' - ')?.[0])
            setValue(`filters.endDate`, values?.createdDate?.split(' - ')?.[1])
        }

        if (title) {
            setValue('title', title)
            if (!query) query = `?title=${title}`
            else query += `&title=${title}`
        }

        await fetchRssBreaking(query)
    }


    const removeFilter = async (key = '') => {
        if (!key) return
        setValue(`filters.${key}`, null)
        setValue(key, null)
        await filterButtonClick()
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel">RSS</h5>
                    <button onClick={filterButtonClick} className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Apply Filters</button>
                </div>
                <div className="offcanvas-body">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Name
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={rssFeedOptions}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Source
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="source"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={sourceOptions}
                                            />
                                        )}
                                    />
                                </div>
                            </div>


                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Genre
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="genre"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={genreOptions}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    State
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={states}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    City
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={cities}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Continent
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="continent"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={continents}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Country
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={countries}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="daterangeCreated" className="col-sm-4 col-form-label text-right">
                                    Created Date
                                </label>
                                <div className="col-sm-8">
                                    <input type="text" name="daterangeCreated" placeholder="Select Created Date Range" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">
                        <div className="card-header">
                            <h3 className="text-bold">Rss Breaking</h3>
                            <input
                                type="search"
                                placeholder="Enter title"
                                className="form-control story-search-input"
                                onKeyDown={async (e) => {
                                    if (e.key === 'Enter') {
                                        await filterButtonClick(e.target.value)
                                    }
                                }}
                            />

                            <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => { }}>Filter</button>
                        </div>

                        <div className="filters-container">
                            <p>Applied Filters: </p>
                            {Object.keys(watch('filters') || {}).map((key) => (
                                watch('filters')[key]?.label || watch('filters')[key] && (
                                    <p className="current-filters ml-10">
                                        {key}: {watch('filters')[key]?.label || watch('filters')[key]}
                                        <span className='filter-close' onClick={() => removeFilter(key)}>X</span>
                                    </p>
                                )
                            ))}
                        </div>

                        <div className="table-responsive">
                            <table className="table table-hover">
                                <tbody>
                                    {!rssBreaking?.length > 0 || loading ? (
                                        <Loader />
                                    ) : (
                                        rssBreaking?.map((story, index) => {
                                            return (
                                                <tr key={story?._id}>
                                                    <td style={{ lineHeight: '0.8em', widht: '95%' }} >
                                                        <p
                                                            onMouseLeave={(e) => e.target.style.color = 'initial'}
                                                            style={{ cursor: 'pointer', fontSize: '1.3rem', lineHeight: '1.4' }}
                                                        >
                                                            <a href={`/rss-breaking/edit/${story?._id}`} target='_blank'>
                                                                {story?.title}
                                                            </a>
                                                        </p>

                                                        <div style={{ display: 'flex', justifyContent: 'left', fontSize: '1rem', height: '1.2em' }}>
                                                            <p className="slsd-col">
                                                                <span className="fw-500 mr-2">Name:</span>
                                                                {story?.rssFeed?.name}
                                                            </p>

                                                            <p className="slsd-col ml-15">
                                                                <span className="fw-500 mr-2">Date:</span>
                                                                {moment(story?.createdAt).format("DD MMMM YYYY, hh:mm A")}
                                                            </p>

                                                            <p className="slsd-col ml-15">
                                                                <span className="fw-500 mr-2">Status:</span>
                                                                {story?.status}
                                                            </p>

                                                            <p className="slsd-col ml-15">
                                                                <span className="fw-500 mr-2">Genre:</span>
                                                                {story?.rssFeed?.genre || '-'}
                                                            </p>

                                                            <p className="slsd-col ml-15">
                                                                <span className="fw-500 mr-2">Source:</span>
                                                                {story?.rssFeed?.source || '-'}
                                                            </p>

                                                            <p className="slsd-col ml-15">
                                                                <span className="fw-500 mr-2">Type:</span>
                                                                {story?.rssFeed?.type}
                                                            </p>

                                                            {
                                                                story?.rssFeed?.type === 'national' &&
                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">State:</span>
                                                                    {story?.rssFeed?.state || '-'}
                                                                </p>
                                                            }

                                                            {
                                                                story?.rssFeed?.type === 'national' &&
                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">City:</span>
                                                                    {story?.rssFeed?.city || '-'}
                                                                </p>
                                                            }

                                                            {
                                                                story?.rssFeed?.type === 'international' &&
                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">Continent:</span>
                                                                    {story?.rssFeed?.continent || '-'}
                                                                </p>
                                                            }

                                                            {
                                                                story?.rssFeed?.type === 'international' &&
                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">Country:</span>
                                                                    {story?.rssFeed?.country || '-'}
                                                                </p>
                                                            }

                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ListStory;
